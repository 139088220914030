import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageBuilder from '../components/PageBuilder'
import AgeConsentPopup from '@shared/components/AgeConsentPopup'

const GenericPage = ({ data }) => {
  const {
    title,
    description,
    node_locale: nodeLocale,
    slug,
    pageLayout,
  } = data.contentfulPageGeneric

  return (
    <Layout>
      <SEO
        title={title}
        lang={nodeLocale}
        description={description}
        slug={slug}
      />
      <AgeConsentPopup />
      <PageBuilder pageLayout={pageLayout} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulPageGeneric(id: { eq: $id }) {
      ...GenericPageFragment
    }
  }
`

export default GenericPage
