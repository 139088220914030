import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import responsiveType from '../styles/mixins/typography'
import { rem } from '@shared/styles/mixins/sizing'
import { css } from '@emotion/core'
import mediaQuery from '@shared/styles/mixins/mediaQuery'

const BUTTON_SIZES = {
  SM: 'sm',
  LG: 'lg',
}

const BUTTON_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

const StyledButton = styled.button`
  ${responsiveType('label')}

  ${({ theme }) => css`
    ${theme.borders.alternate}
    color: ${theme.colors.primary};
    padding: ${theme.gutters.sm} ${theme.gutters.md}};
    margin: ${theme.gutters.sm} 0;
  `};

  display: inline-block;
  background: transparent;
  height: ${rem(84)};
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  max-width: ${rem(256)};
  width: 100%;
  transition: all ${props => props.theme.transition.fast};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: none;
    `}

  ${mediaQuery('<mobileLarge')} {
    max-width: none;
  }

  &:hover,
  &:focus {
    ${props => props.theme.borders.default}
    color: ${props => props.theme.colors.highlight};
  }

  &:active {
    transform: scale(0.99);
  }
`

const Button = ({
  children,
  disabled,
  fullWidth,
  loading,
  theme,
  size,
  ...buttonProps
}) => {
  return (
    <StyledButton
      disabled={disabled}
      theme={theme}
      fullWidth={fullWidth}
      {...buttonProps}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(BUTTON_THEME)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
}

export default Button
