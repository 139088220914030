import { useState, useEffect, useRef } from 'react'
import Typewriter from '@shared/utils/typewriter'

const useTypewriter = (targetText, typingFinishedCallback) => {
  const [text, setText] = useState('')
  const intervalRef = useRef()
  const typewriter = useRef()

  useEffect(() => {
    if (!typewriter.current) {
      typewriter.current = new Typewriter()
    }

    setText(typewriter.current.startNextText(targetText))

    intervalRef.current = setInterval(() => {
      const updatedText = typewriter.current.type()

      setText(updatedText)

      if (updatedText === targetText) {
        clearInterval(intervalRef.current)

        if (typeof typingFinishedCallback === 'function') {
          typingFinishedCallback()
        }
      }
    }, typewriter.current.randomTypingSpeed())

    return () => clearInterval(intervalRef.current)
  }, [targetText, typingFinishedCallback])

  return [text]
}

export default useTypewriter
