import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  fireCustobarEvent,
  getCustobarEventParams,
} from '../utils/custobarEvents'
import Form from './Form'
import TypewriterText from './TypewriterText'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  position: relative;
`

const ThanksWrapper = styled.div`
  position: absolute;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustobarForm = ({
  event,
  inputProps,
  mailingLists = [],
  submitSuccessfulMessage,
  ...formProps
}) => {
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = ({ email }) => {
    fireCustobarEvent(event, email, getCustobarEventParams(event, mailingLists))
    setTimeout(() => setSubmitted(true), 600)
  }

  return (
    <Wrapper>
      <Form {...formProps} inputProps={inputProps} onSubmit={onSubmit} />
      {submitted && (
        <ThanksWrapper>
          <TypewriterText titles={['Danke schön']} element="h1" />
        </ThanksWrapper>
      )}
    </Wrapper>
  )
}

CustobarForm.propTypes = {
  title: PropTypes.string,
  inputFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  inputProps: PropTypes.shape(),
  submitButtonLabel: PropTypes.string.isRequired,
  event: PropTypes.string,
  mailingLists: PropTypes.arrayOf(PropTypes.string),
  submitSuccessfulMessage: PropTypes.string,
}

export default CustobarForm
