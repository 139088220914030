import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import responsiveType from '@shared/styles/mixins/typography'

const Overlay = styled.div`
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Popup = styled.div`
  width: 100%;
  background: #ffffff;
  position: absolute;
  text-align: center;

  padding: ${props => props.theme.gutters.md};
  box-sizing: border-box;
  box-shadow: 0 -50px 100px rgba(0, 0, 0, 0.2);

  p {
    color: ${props => props.theme.colors.alternateText};
    font-size: 14px;
    line-height: 24px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`

const PopupButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.gutters.md};
  margin-bottom: ${props => props.theme.gutters.md};
`

const PopupButton = styled.button`
  ${responsiveType('turboHeadline')}
  margin: 0 ${props => props.theme.gutters.sm};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.primary};
  }
`

const goToGoogle = () => {
  window.location.href = 'https://www.google.de'
}

const loadAcceptedPopup = () => {
  try {
    const serializedState = localStorage.getItem('accepted-popup')
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveAcceptedPopup = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('accepted-popup', serializedState)
  } catch {
    // ignore write errors
  }
}

const AgeConsentPopup = () => {
  const [accepted, setAccepted] = useState(loadAcceptedPopup())

  const onAccept = () => {
    saveAcceptedPopup(true)
    setAccepted(true)
  }

  if (accepted || typeof window === 'undefined') {
    return null
  }

  return (
    <Overlay>
      <Popup>
        <h3>Bist Du alt genug, um in Deinem Wohnsitz zu trinken?</h3>
        <PopupButtons>
          <PopupButton onClick={onAccept}>Ja</PopupButton>
          <PopupButton onClick={goToGoogle}>Nein</PopupButton>
        </PopupButtons>
        <p>
          Mit dem Betreten dieser Website erklärst Du Dich mit unseren
          Datenschutz und Cookie Richtlinien einverstanden.
        </p>
        <p>
          Vertrauen ist uns ganz besonders wichtig - bitte les' hier unsere{' '}
          <a
            href="https://kyrodistillery.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzerklärung hier.
          </a>
        </p>
      </Popup>
    </Overlay>
  )
}

AgeConsentPopup.propTypes = {}

export default AgeConsentPopup
