import { useState, useEffect } from 'react'

const useBeforeFirstRender = callback => {
  const [hasRendered, setHasRendered] = useState(false)

  useEffect(() => setHasRendered(true), [hasRendered])

  if (!hasRendered) {
    callback()
  }
}

export default useBeforeFirstRender
