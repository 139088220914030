import { css } from '@emotion/core'
import theme from '../../theme'
import mediaQuery from './mediaQuery'

const responsiveType = style => {
  const breakpointStyles = theme.type[style]

  if (breakpointStyles instanceof Object === false) {
    console.error(
      `responsiveType: style must exist in theme.type, received '${style}'`
    )

    return
  }

  return css`
    ${Object.keys(breakpointStyles).map(breakpoint =>
      breakpoint === 'default'
        ? breakpointStyles[breakpoint]
        : css`
            ${mediaQuery(breakpoint)} {
              ${breakpointStyles[breakpoint]}
            }
          `
    )}
  `
}

export default responsiveType
