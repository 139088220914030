import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import useBeforeFirstRender from '../hooks/useBeforeFirstRender.js'
import barsJson from '../assets/rye_berlin.json'
import lounge from '../assets/icons/lounge.svg'
import bar from '../assets/icons/bar.svg'
import responsiveType from '@shared/styles/mixins/typography.js'

const iconConfig = {
  iconAnchor: [0, 32],
  popupAnchor: [16, -40],
  iconSize: [32, 38],
}

const StyledMap = styled(Map)`
  height: 420px;
  height: 50vh;
  width: 100%;
`

const BarTitle = styled.h5`
  ${responsiveType('ingress')}
`

const BarAddress = styled.p`
  ${responsiveType('body')}
`

const KyroMap = () => {
  const loungeIconRef = useRef()
  const barIconRef = useRef()

  useBeforeFirstRender(() => {
    // Leaflet attaches to the DOM, so prevent usage in Gatsby SSR

    if (typeof window !== 'undefined') {
      const L = require('leaflet')

      loungeIconRef.current = new L.Icon({
        iconUrl: lounge,
        iconRetinaUrl: lounge,
        ...iconConfig,
      })

      barIconRef.current = new L.Icon({
        iconUrl: bar,
        iconRetinaUrl: bar,
        ...iconConfig,
      })
    }
  })

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <StyledMap center={[52.52, 13.405]} zoom={12}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}"
        minZoom={10}
        maxZoom={18}
        ext="png"
      />
      {barsJson.map(bar => (
        <Marker
          key={bar.name}
          position={[bar.lat, bar.long]}
          icon={
            bar.type === 'Bottle shop'
              ? barIconRef.current
              : loungeIconRef.current
          }
        >
          <Popup>
            <a
              href={`https://maps.google.com/?q=${encodeURI(bar.name)}+Berlin`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BarTitle>{bar.name}</BarTitle>
              <BarAddress>{bar.address}</BarAddress>
            </a>
          </Popup>
        </Marker>
      ))}
    </StyledMap>
  )
}

export default KyroMap
