export default class Typewriter {
  nextText = ''
  currentText = ''
  remainingText = ''
  erasing = false

  type() {
    // Erasing finished, start the next work
    if (this.erasing && !this.currentText) {
      return this.restartTypewriter()
    }

    // Erasing
    if (this.erasing && this.currentText) {
      return this.erase()
    }

    // Typing finished
    if (this.currentText === this.nextText) {
      return this.currentText
    }

    // Typing
    return this.typeNextLetter()
  }

  startNextText(nextText) {
    // Start by erasing the current text
    this.erasing = true
    this.nextText = nextText

    return this.erase()
  }

  restartTypewriter() {
    this.remainingText = this.nextText || ''
    this.erasing = false

    return ''
  }

  typeNextLetter() {
    this.currentText += this.remainingText.charAt(0)
    this.remainingText = this.remainingText.slice(1)

    return this.currentText
  }

  erase() {
    this.currentText = this.currentText.slice(0, this.currentText.length - 1)

    return this.currentText
  }

  randomTypingSpeed(min = 70, max = 90) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
