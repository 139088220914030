import { useState } from 'react'

const useForm = onSubmitCallback => {
  const [inputs, setInputs] = useState({})

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }

    if (typeof onSubmitCallback === 'function') {
      onSubmitCallback(inputs)
    }
  }

  const handleInputChange = event => {
    event.persist()

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  }
}

export default useForm
