import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import TypewriterText from './TypewriterText'
import CustobarForm from './CustobarForm'

const inputProps = { withLabel: false }

const Subtitle = styled.h4`
  color: ${props => props.theme.colors.primary};
  margin-top: ${props => props.theme.gutters.md};
  margin-bottom: ${props => props.theme.gutters.md};
`

const Wrapper = styled.div`
  text-align: center;
`

const CarouselCta = ({
  titles,
  subtitle,
  ctaButtonText,
  ctaButtonLink,
  form,
}) => {
  return (
    <Wrapper>
      <TypewriterText titles={titles} element="h1" />
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {form && <CustobarForm {...form} inputProps={inputProps} />}
    </Wrapper>
  )
}

CarouselCta.propTypes = {
  className: PropTypes.string,
  ctaButtonLink: PropTypes.string,
  ctaButtonText: PropTypes.string,
  form: PropTypes.shape(),
  titles: PropTypes.arrayOf(PropTypes.string),
}

export default CarouselCta
