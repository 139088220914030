import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Image from 'gatsby-image'
import mediaQuery from '@shared/styles/mixins/mediaQuery'

// TODO:
// - Different renders by LAYOUT options
// - Decide on video controls strategy, custom, hide or show

const LAYOUT = {
  TEXT_ON_MEDIA: 'Text on Media',
  MEDIA_TEXT: 'Media | Text',
  TEXT_MEDIA: 'Text | Media',
}

const SplitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery('<tablet')} {
    flex-wrap: wrap-reverse;
    padding: 0;
  }
`

const Column = styled.div`
  width: 100%;
`

const TextColumn = styled(Column)`
  /* TODO: Grid system and right or left based on layout */
  margin-left: ${props => props.theme.gutters.md};
  margin-right: ${props => props.theme.gutters.md};
  margin-bottom: ${props => props.theme.gutters.md};
  padding-right: 12.5%;

  ${mediaQuery('<tablet')} {
    padding: 0;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.gutters.md};

  ${mediaQuery('<tablet')} {
    padding: 0;
  }

  video {
    background: black;
  }
`

const Video = ({ src, type }) => {
  /**
   * dangerouslySetInnerHTML to support the video muted property with React
   * This is required to support autoplaying videos on mobile devices
   *
   * This is an ongoing discussion within the React team, and can be removed if the below issue is resolved:
   * https://github.com/facebook/react/issues/10389
   */
  return (
    <VideoContainer
      dangerouslySetInnerHTML={{
        __html: `
          <video width="100%" height="auto" playsinline webkit-playsinline muted loop controls>
            <source src="${src}" type="${type}"></source>
          </video>
        `,
      }}
    />
  )
}

const MediaHero = ({ title, text, media, layout }) => {
  const { contentType } = media.file

  if (contentType === 'video/mp4') {
    return <Video src={media.file.url} type={contentType} />
  }

  if (contentType === 'image/jpeg') {
    return (
      <SplitWrapper>
        {media.fluid && (
          <Column>
            <Image fluid={media.fluid} />
          </Column>
        )}

        <TextColumn>
          {title && <h1>{title}</h1>}
          {text && text.childMarkdownRemark && (
            <div
              dangerouslySetInnerHTML={{
                __html: text.childMarkdownRemark.html,
              }}
            />
          )}
        </TextColumn>
      </SplitWrapper>
    )
  }

  return null
}

MediaHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.shape(),
  media: PropTypes.shape(),
  layout: PropTypes.oneOf(Object.values(LAYOUT)),
}

export default MediaHero
