import { MEDIA_QUERIES } from '@shared/theme'

const mediaQuery = breakpoint => {
  const query = MEDIA_QUERIES[breakpoint]

  if (!query) {
    console.error(
      `mediaQuery: breakpoint must exist in MEDIA_QUERIES, received '${breakpoint}'`
    )
  }

  return query
}

export default mediaQuery
