import React from 'react'
import styled from '@emotion/styled'
import mediaQuery from '@shared/styles/mixins/mediaQuery'
import { MODULE_TYPE } from '../constants/contentfulTypenames'
import { fullWidth } from '../styles/mixins/sizing'
import CustobarForm from './CustobarForm'
import CarouselCta from './CarouselCta'
import MediaHero from './MediaHero'

const Module = styled.section`
  margin: ${props => props.theme.gutters.lg} 0;
  ${props => props.fullWidth && fullWidth}

  ${mediaQuery('<tablet')} {
    margin: ${props => props.theme.gutters.md} 0;
    ${props => props.fullWidth && fullWidth}
  }
`

const renderSectionByTypename = section => {
  if (!section) {
    return null
  }

  switch (section.__typename) {
    case MODULE_TYPE.CAROUSEL_CTA:
      return (
        <Module key={section.id}>
          <CarouselCta {...section} />
        </Module>
      )

    case MODULE_TYPE.MEDIA_HERO:
      return (
        <Module key={section.id} fullWidth>
          <MediaHero {...section} />
        </Module>
      )

    case MODULE_TYPE.FORM:
      return (
        <Module key={section.id}>
          <CustobarForm {...section} />
        </Module>
      )

    default:
      return null
  }
}

const PageBuilder = ({ pageLayout }) => {
  if (pageLayout instanceof Array === false || pageLayout.length === 0) {
    return null
  }

  return <>{pageLayout.map(renderSectionByTypename)}</>
}

export default PageBuilder
