import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Input from './Input'
import Button from './Button'
import useForm from '../hooks/useForm'

const StyledForm = styled.form`
  max-width: ${({ theme }) => theme.sizes.maxWidthCentered};
  margin: 0 auto;
`

const Disclaimer = styled.p`
  font-size: 12px;
  margin: 0;
  color: ${props => props.theme.colors.alternateText};
  max-width: 260px;
  margin: 0 auto;
`

const Form = ({
  inputFields,
  inputProps,
  onSubmit,
  submitButtonLabel,
  title,
}) => {
  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit)

  return (
    <StyledForm onSubmit={handleSubmit}>
      {title && <h1>{title}</h1>}

      {inputFields.map(input => (
        <Input
          {...input}
          {...inputProps}
          key={input.id}
          name={input.key}
          value={inputs[input.key] || ''}
          onChange={handleInputChange}
        />
      ))}

      <Button type="submit">{submitButtonLabel}</Button>
      <Disclaimer>
        Wir respektieren Deine Privatsphäre und geben Deine Daten nicht an
        Dritte weiter.
      </Disclaimer>
    </StyledForm>
  )
}

Form.propTypes = {
  inputFields: PropTypes.arrayOf(PropTypes.shape()),
  inputProps: PropTypes.shape(),
  submitButtonLabel: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default Form
