import React, { useState, useCallback, createElement, useRef } from 'react'
import styled from '@emotion/styled'
import useTypewriter from '../hooks/useTypewriter'
import useDimensions from '../hooks/useDimensions'
import useCompareStrings from '../hooks/useCompareStrings'
import useBeforeFirstRender from '../hooks/useBeforeFirstRender'

const CAROUSEL_DELAY = 2000

const getNextTitle = (currTitle, titles) => {
  const nextIndex = titles.indexOf(currTitle) + 1

  return titles[nextIndex] !== undefined ? titles[nextIndex] : titles[0]
}

const getLongestString = titles => titles.sort((a, b) => b.length - a.length)[0]

const Text = styled.span`
  position: relative;

  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 0;

  /* Add padding to fix Safari bug where umlauts above the span bounding box are cut off */
  padding-top: 10px;
  padding-bottom: 10px;
`

const CommonText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
`

const HiddenText = styled(Text)`
  visibility: hidden;
`

// TODO: Prop types, change height on browser resize

const TypewriterText = ({ titles, element }) => {
  let longestTitleRef = useRef()
  const [title, setTitle] = useState(titles[0])
  const [commonText] = useCompareStrings(titles)

  useBeforeFirstRender(() => {
    longestTitleRef.current = getLongestString(titles)
  })

  const onTypingFinished = useCallback(
    () =>
      setTimeout(() => setTitle(getNextTitle(title, titles)), CAROUSEL_DELAY),
    [title, titles]
  )

  const typingTitle = useTypewriter(
    commonText ? title.replace(commonText, '') : title,
    onTypingFinished
  )

  const [ref, { height }] = useDimensions()

  return createElement(
    element,
    { 'aria-label': title },
    <div ref={ref} style={{ height }}>
      {height ? (
        <>
          {commonText ? <CommonText>{commonText}</CommonText> : null}
          <Text>{typingTitle} </Text>
        </>
      ) : (
        <HiddenText>{longestTitleRef.current}</HiddenText>
      )}
    </div>
  )
}

export default TypewriterText
