import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
// import { rem } from '@shared/styles/mixins/sizing'

const Wrapper = styled.div`
  margin: ${props => props.theme.gutters.sm} 0;
`

const Text = styled.h4`
  &::after {
    color: red;
    content: '${props => (props.required ? '*' : null)}';
  }
`

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  outline: none;

  ${({ theme }) => `
    border-bottom: 2px solid ${theme.colors.text};
    padding-top: ${theme.gutters.sm};
    padding-bottom: ${theme.gutters.sm};
    transition: border ${theme.transition.fast};
  `};

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.borders.highlightColor};
  }
`

const Input = ({
  label,
  name,
  onChange,
  placeholder,
  required,
  type,
  value,
  withLabel = true,
  ...inputProps
}) => (
  <Wrapper>
    <label>
      {withLabel && <Text required={required}>{label}</Text>}

      <StyledInput
        type={type || 'text'}
        name={name}
        placeholder={placeholder || (!withLabel ? label : null)}
        onChange={onChange}
        value={value}
        required={required}
        {...inputProps}
      />
    </label>
  </Wrapper>
)

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  withLabel: PropTypes.bool,
}

export default Input
