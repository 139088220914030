import { css } from '@emotion/core'
import theme from '@shared/theme'
import responsiveType from './mixins/typography'

export default css`
  html,
  body {
    height: 100%;
  }

  body {
    ${responsiveType('body')}
    background: ${theme.blocks.light.background};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.fonts.heading};
  }

  h1 {
    ${responsiveType('headline')}
  }

  footer {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }
`
