import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import globalStyles from '@shared/styles/global'
import logo from '@shared/assets/images/KDC_Logo_Black.svg'
import Footer from '../components/Footer'

import '@shared/styles/fonts.css'
import '@shared/styles/normalize.css'

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.header`
  margin-top: ${props => props.theme.gutters.lg};
  margin-bottom: ${props => props.theme.gutters.sm};
`

const Main = styled.main`
  width: 100%;
  display: flex;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0;
  padding: 0 ${({ theme }) => theme.gutters.md};
  box-sizing: border-box;
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }

  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <Root>
      <Global styles={globalStyles} />
      <Header>
        <img src={logo} alt="Kyrö Distillery Company Logo" />
      </Header>
      <Main>{props.children}</Main>
      <Footer />
    </Root>
  )
}

export default Layout
