import formatISO from 'date-fns/formatISO'

// https://www.custobar.com/docs/api/events/

const CUSTOBAR_EVENTS = {
  BROWSE: 'BROWSE',
  MAIL_SUBSCRIBE: 'MAIL_SUBSCRIBE',
  MAIL_UNSUBSCRIBE: 'MAIL_UNSUBSCRIBE',
  BASKET_ADD: 'BASKET_ADD',
  SENTIMENT: 'SENTIMENT',
}

const isMailEvent = event =>
  event === CUSTOBAR_EVENTS.MAIL_SUBSCRIBE ||
  event === CUSTOBAR_EVENTS.MAIL_UNSUBSCRIBE

const getUrlParams = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  return urlParams
}

const getUtmParams = urlParams => {
  const params = [...urlParams.entries()]

  return params
    .filter(([key, value]) => key.includes('utm') && !!value)
    .reduce((params, [key, value]) => ({ ...params, [key]: value }), {})
}

export const getCustobarEventParams = (event, mailingLists = []) => {
  const urlParams = getUrlParams()

  let params = {
    date: formatISO(new Date()),
  }

  if (urlParams && [...urlParams.keys()].length) {
    const utmParams = getUtmParams(urlParams)

    params = {
      ...params,
      ...utmParams,
    }
  }

  if (isMailEvent(event)) {
    params = {
      ...params,
      mailing_lists: mailingLists,
    }
  }

  return params
}

export const fireCustobarEvent = (event, email, params) => {
  try {
    window.cstbr.push({
      type: event,
      email: email,
      ...params,
    })
  } catch (error) {}
}
