import { useState, useEffect } from 'react'

/**
 * Compare multiple strings and return the match from the start of each string
 * e.g. useCompareStrings(['What time', 'What day', 'What week']) returns 'What'
 *
 * @param {Array} strings
 */
const useCompareStrings = strings => {
  const [match, setMatch] = useState('')

  useEffect(() => {
    let shortestString = strings.sort((a, b) => a.length - b.length)[0]

    // Use for loops so we can return after finding a match to prevent extra iterations
    for (let charIndex = 0; charIndex < shortestString.length; charIndex++) {
      const currChar = shortestString[charIndex]

      for (let stringIndex = 0; stringIndex < strings.length; stringIndex++) {
        const currString = strings[stringIndex]

        if (currString[charIndex] !== currChar) {
          setMatch(shortestString.slice(0, charIndex))
          return
        }
      }
    }
  }, [strings])

  return [match]
}

export default useCompareStrings
