import React from 'react'
import styled from '@emotion/styled'
import facebook from '../assets/icons/facebook.svg'
import insta from '../assets/icons/instagram.svg'
import KyroMap from './KyroMap'
import mediaQuery from '@shared/styles/mixins/mediaQuery'

const StyledFooter = styled.footer`
  width: 100%;
  background: ${({ theme }) => theme.blocks.dark.background};
  color: ${({ theme }) => theme.blocks.dark.foreground};
  text-align: center;

  margin-top: ${props => props.theme.gutters.lg};

  ${mediaQuery('<tablet')} {
    margin: ${props => props.theme.gutters.md};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`

const FooterLinks = styled.div`
  text-align: center;
  margin: ${props => props.theme.gutters.lg} ${props => props.theme.gutters.md};
`

const IconLink = styled.a`
  margin: 0 ${props => props.theme.gutters.sm};
`

const SocialLink = ({ href, alt, icon }) => (
  <IconLink href={href} target="_blank" rel="noopener noreferrer">
    <img alt={alt} src={icon} />
  </IconLink>
)

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <KyroMap />
      </div>
      <FooterLinks>
        <h4>Folg' uns</h4>
        <SocialLink
          href="https://www.facebook.com/kyrodistillery/"
          alt="Facebook"
          icon={facebook}
        />

        <SocialLink
          href="https://www.instagram.com/kyrodistillery/"
          alt="Instagram"
          icon={insta}
        />
      </FooterLinks>

      <FooterLinks>
        <p>
          © Kyrö Distillery Company -
          <a href="https://kyrodistillery.com/cookie-policy/">
            Datenschutz und Cookie Richtlinien
          </a>
        </p>
        <p>
          Vertrauen ist uns ganz besonders wichtig - bitte les' hier unsere{' '}
          <a
            href="https://kyrodistillery.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzerklärung hier.
          </a>
        </p>
      </FooterLinks>
    </StyledFooter>
  )
}

export default Footer
